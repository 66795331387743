














import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { readAdminUsers } from '@/store/admin/getters';
import { dispatchGetUsers } from '@/store/admin/actions';

@Component
export default class AdminUsers extends Vue {
  public headers = [
    // {
    //   text: 'Name',
    //   sortable: true,
    //   value: 'name',
    //   align: 'left',
    // },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Full Name',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'active?',
      sortable: true,
      value: 'is_active',
      align: 'left',
    },
    {
      text: 'email verified?',
      sortable: true,
      value: 'email_verified',
      align: 'left',
    },
    {
      text: 'KYC?',
      sortable: true,
      value: 'have_KYC',
      align: 'left',
    },
    {
      text: 'phone',
      sortable: true,
      value: 'phone_number',
      align: 'left',
    },
    {
      text: 'ID',
      sortable: true,
      value: 'ID_number',
      align: 'left',
    },
    // {
    //   text: 'Level',
    //   sortable: true,
    //   value: 'user_level',
    //   align: 'left',
    // },
    // {
    //   text: 'active?',
    //   sortable: true,
    //   value: 'could_buy_active',
    //   align: 'left',
    // },
    {
      text: 'Invite Code',
      sortable: true,
      value: 'invite_code',
      align: 'left',
    },
    {
      text: 'Used Code',
      sortable: true,
      value: 'used_invite_code',
      align: 'left',
    },
    // {
    //   text: 'Bonus User',
    //   sortable: true,
    //   value: 'bonus_user',
    //   align: 'left',
    // },
    // {
    //   text: 'Bonus User Harvested',
    //   sortable: true,
    //   value: 'bonus_user_harvested',
    //   align: 'left',
    // },
    {
      text: 'Invited ID',
      sortable: true,
      value: 'invited_user_ids',
      align: 'left',
    },
    {
      text: 'Discount Quota',
      sortable: true,
      value: 'discount_quota',
      align: 'left',
    },
    {
      text: 'Address',
      sortable: true,
      value: 'address',
      align: 'left',
    },
    // {
    //   text: 'Bonus Max',
    //   sortable: true,
    //   value: 'bonus_max',
    //   align: 'left',
    // },
    // {
    //   text: 'Bonus KYC',
    //   sortable: true,
    //   value: 'bonus_kyc',
    //   align: 'left',
    // },
    // {
    //   text: 'Actions',
    //   value: 'id',
    // },
  ];
  get users() {
    return readAdminUsers(this.$store);
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
  }
}
